.react-datepicker {
  font-family: 'Optimist', sans-serif;
  font-size: 0.9rem;
  background-color: #fff;
  color: #000;
  border-radius: 0.3rem;
  margin: 12px 6px 0px 6px;
}
.react-datepicker__month-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #d4d4d4;
  margin: 0px 4px 0px 4px;
}
.react-datepicker__week:after {
  content: '';
  position: absolute;
}
.react-datepicker__week:last-child {
  border-bottom: none;
}
.react-datepicker__aria-live {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1;
  padding: 0;
  border: 0;
}
.react-datepicker__day-names {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #d4d4d4;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker {
    margin: 0px 4px 0px 4px;
  }
}
.react-datepicker__day-name,
.react-datepicker__day {
  color: #000;
  display: inline-block;
  width: calc(100% / 6);
  line-height: 1.7rem;
  font-size: 14px;
  text-align: center;
  margin: 0rem 0.166rem 0rem 0.166rem;
  padding-bottom: 8px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .react-datepicker__day-name,
  .react-datepicker__day {
    line-height: 1.5rem;
  }
}
.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer;
  border: 2px solid transparent;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-color: #0276b1;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected {
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today {
  font-weight: bold;
}
.react-datepicker__day--today::after,
.react-datepicker__month-text--today::after {
  bottom: 1px;
  content: 'Today';
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 0.3rem;
  position: relative;
  margin-top: -2px;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
  cursor: default;
  color: #696969;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
  background-color: transparent;
}
.react-datepicker-popper {
  z-index: 1;
}
