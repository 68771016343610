.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  min-width: 300px;
  padding: 4px;
  margin-top: 24px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: 1px solid #f9f9f9;
}

.rdtPicker.rdtPickerPositionTop {
  top: -370px;
}

.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-collapse: collapse;
}
.rdtPicker tr,
.rdtPicker td {
  font-family: 'Optimist', 'sans-serif';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  height: 38px;
}
.rdtPicker td {
  border-top: 1px solid #d4d4d4;
  padding: 0;
}
.rdtPicker
  td:not(.rdtPreviousMonth):not(.rdtNextMonth):not(.rdtBeforeMinDate):not(.rdtAfterMaxDate):not(.rdtDisabled) {
  cursor: pointer;
}
.rdtPicker thead tr:first-child {
  font-family: 'Optimist', 'sans-serif';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  height: 38px;
}
.rdtDays,
.rdtMonths,
.rdtYears {
  padding: 8px;
}
.rdtPicker
  td.rdtDay:not(.rdtPreviousMonth):not(.rdtNextMonth):not(.rdtBeforeMinDate):not(.rdtAfterMaxDate):not(.rdtDisabled)
  .rdtDayLabel:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  border-color: #0276b1;
}
.rdtPicker td.rdtPreviousMonth,
.rdtPicker td.rdtNextMonth {
  color: #ffffff;
}
.rdtPicker td.rdtBeforeMinDate,
.rdtPicker td.rdtAfterMaxDate {
  font-weight: normal;
  color: #696969;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  bottom: 1px;
  content: 'Today';
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  position: absolute;
  width: 100%;
  z-index: 0;
}
.rdtPicker .rdtDayLabel {
  border: 2px solid transparent;
  box-sizing: border-box;
  height: 100%;
  padding-top: 2px;
  position: relative;
}

.rdtPicker td.rdtYear.rdtActive,
.rdtPicker td.rdtMonth.rdtActive,
.rdtPicker
  td.rdtDay.rdtActive:not(.rdtPreviousMonth):not(.rdtNextMonth):not(.rdtBeforeMinDate):not(.rdtAfterMaxDate) {
  background-color: #0276b1;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtYear.rdtDisabled,
.rdtPicker td.rdtMonth.rdtDisabled,
.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover,
.rdtPicker
  td.rdtDay.rdtDisabled:not(.rdtPreviousMonth):not(.rdtNextMonth):not(.rdtBeforeMinDate):not(.rdtAfterMaxDate),
.rdtPicker td span.rdtDisabled {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtPreviousMonth {
  color: #999999;
}

.rdtPicker th {
  /* border-bottom: 1px solid #D4D4D4; */
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  /* width: 100px; */
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  /* vertical-align: top; */
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
  border: none;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.endAdornment {
  position: absolute;
  right: 0;
  margin-right: 18px;
}

.dropdown {
  margin-left: 10px;
}

.dateView {
  display: flex;
  place-content: center;
}
