body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Optimist';
  src: url('../fonts/Optimist_W_Rg.woff');
}

@font-face {
  font-family: 'OptimistRight';
  src: url('../fonts/Optimist_W_Lt.woff');
}

@font-face {
  font-family: 'PT Sans';
  src: url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
}

#root {
  overflow: hidden;
}
